//@author travis
import { DataAccess } from "../dataaccess/data.access";
import { S25Const } from "../util/s25-const";
import { S25Util } from "../util/s25-util";
import { UserprefService } from "./userpref.service";
import { Cache } from "../decorators/cache.decorator";
import { Proto } from "../pojo/Proto";
import ISODateString = Proto.ISODateString;
import { ValueOf } from "../pojo/Util";

export class TooltipService {
    private static TooltipService_Repository = {
        isSeized: false,
    };

    @Cache({ expireTimeMs: 120000, targetName: "TooltipService", immutable: true })
    public static getTooltip(itemTypeId: number, itemId: number, itemId2: number) {
        let type = S25Const.itemType[itemTypeId].type;
        let itemId2Param = S25Util.isUndefined(itemId2) ? "" : S25Const.item_type_map[type].item2 + itemId2;
        return DataAccess.get<TooltipResponse>(
            DataAccess.injectCaller(
                "/tooltip/" + type + ".json?itemId=" + itemId + "&" + type + "_id=" + itemId + itemId2Param,
                "TooltipService.getTooltip",
            ),
        );
    }

    public static getDisable() {
        return UserprefService.getDisableTooltip();
    }

    public static isSeized() {
        return TooltipService.TooltipService_Repository.isSeized;
    }

    public static setSeized() {
        TooltipService.TooltipService_Repository.isSeized = true;
    }

    public static setFree() {
        TooltipService.TooltipService_Repository.isSeized = false;
    }
}

export type TooltipResponse = {
    item: {
        event_id?: number;
        event_name?: string;
        rsrv_start_dt?: ISODateString;
        rsrv_end_dt?: ISODateString;
        rows: {
            data: {
                name: string;
                type: TooltipRowType;
                value?: unknown;
            }[];
        };
        spaces?: {
            data: {
                space_id: number;
                space_name: string;
            }[];
        };

        [key: string]: any;
    };
};

export type TooltipRowType = ValueOf<typeof tooltipRowType>;
export const tooltipRowType = {
    Template: -4,
    LabelledList: -3,
    Bullets: -2,
    Item: -1, // s25-item-generic
    Raw: 1,
    HTML: 3,
    EventTime: 2,
};
