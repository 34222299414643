//@author: mandy

import { Timeout } from "../../decorators/timeout.decorator";
import { UserprefService } from "../userpref.service";
import { EventTaskListStateChange, TaskService } from "./task.service";
import { S25Util } from "../../util/s25-util";
import { OlsService } from "../ols.service";
import { FlsService } from "../fls.service";
import { LangService } from "../lang.service";
import { EventService } from "../event.service";
import { jSith } from "../../util/jquery-replacement";
import { S25Const } from "../../util/s25-const";
import { Task } from "../../pojo/Task";
import { S25LoadingApi } from "../../modules/s25-loading/loading.api";
import { PreferenceService } from "../preference.service";
import TaskListI = Task.TaskListI;
import { TaskTiersService } from "./task.tiers.service";
import { ListGeneratorService } from "../list.generator.service";
import { TaskSelectionMap } from "./task.selection.map";
import { Item } from "../../pojo/Item";

export class TaskActionCellFactory {
    public static getActionCellsOls = S25Util.debounceTs(
        (eventIds: number[]) => {
            return OlsService.getOls(eventIds, Item.Ids.Event, "edit");
        },
        250,
        false,
        0,
        true,
    );

    public static generateActionCell(modelBean: any, listModelBean: any) {
        modelBean.hasApproveDenyPerms = false;
        modelBean.hasCancelPerms = false;

        /**
         * @param isApproval
         * @param isCancel
         * @param scope
         */
        modelBean.changeTask = function (isApproval: boolean, isCancel: boolean, scope: any) {
            let newState: Task.State = isCancel
                ? Task.States.Cancelled
                : isApproval
                  ? Task.States.Completed
                  : Task.States.Denied;
            scope && S25LoadingApi.init(scope);
            return TaskService.changeTask(modelBean, S25Util.parseInt(newState)).then(
                function (results: EventTaskListStateChange[]) {
                    //process results, which could be an array of multiple item updates in one event PUT batch
                    results = S25Util.array.forceArray(results);
                    results.forEach((result) => {
                        result.item.itemStateId = newState; //set new state id (caller may set a callback to refresh the container taskAction is in, which will update the taskAction)
                        result.item.overallStateId = (result.success && result.overallState) || null; //set overall state of task on success

                        //if only one item in play here, and it was denied, set state and overall state to denied
                        if (result.error === "Deny" && result.item.itemCount === 1) {
                            newState = Task.States.Denied;
                            result.item.overallStateId = newState;
                        } else if (result.error === "Deny") {
                            //if multiple tasks and error was Deny, set state to overall state id
                            newState = result.overallState;
                            result.item.overallStateId = newState;
                        }

                        result.item.overallStateName = TaskService.taskStateToStateText(
                            result.item.overallStateId,
                            result.item.itemTypeId,
                        ); //overall state name

                        let contextId =
                            (result.item.isTodo ? "todo" : "task") +
                            (result.item.itemCount > 1 ? "_multi_" : "") +
                            result.item.itemId;
                        let row = listModelBean.getRow && listModelBean.getRow(contextId);

                        //if this item exists in an s25-list and we have a row number to know which cell to update...
                        if (row >= 0 && result && (result.success || result.error === "Deny")) {
                            //task item update to newState (user state) bc this itemStateId is used to drive actions in the modal when clicked
                            //and after a user updates their state, we want to change actions
                            listModelBean.editCell({ itemStateId: newState }, row, "task_item", true, false);

                            //task item update to newState (user state) bc this itemStateId is used to drive actions
                            //and after a user updates their state, we want to change actions
                            listModelBean.editCell({ itemStateId: newState }, row, "actions", true, false);

                            let assignedToCell = listModelBean.getCell(row, "assigned_to"); //get assigned to cell
                            let assignedToTask = assignedToCell && assignedToCell.task; //get task listed on that cell
                            //update contacts array in assigned_to cell to the new array values returned from the service call
                            if (assignedToTask && assignedToTask.approval_contact) {
                                assignedToTask.approval_contact = result.success && result.success.contacts;
                                listModelBean.editCell({ task: assignedToTask }, row, "assigned_to", true, false);
                            }

                            //status is updated to overall state id so users can still see the overall state
                            listModelBean.editCell(
                                { itemStateId: result.item.overallStateId, itemName: result.item.overallStateName },
                                row,
                                "status",
                                true,
                                false,
                            );

                            listModelBean.refreshListBindings && listModelBean.refreshListBindings();
                        }

                        result?.success &&
                            result.item.callback &&
                            result.item.callback(newState, result.item.overallStateName, result.success.contacts);
                    });
                    scope && S25LoadingApi.destroy(scope);
                },
                function (error) {
                    console.error(error);
                    scope && S25LoadingApi.destroy(scope);
                },
            );
        };

        modelBean.approve = function (scope: any) {
            return modelBean.changeTask(true, null, scope);
        };

        modelBean.deny = function (scope: any) {
            return modelBean.changeTask(false, null, scope);
        };

        modelBean.cancel = function (scope: any) {
            return modelBean.changeTask(null, true, scope);
        };

        modelBean.initF = function () {
            modelBean.init = false;

            return S25Util.all({
                currentContactId: modelBean.currentContactId || UserprefService.getContactId(),
                ols:
                    modelBean.eventId &&
                    (modelBean.editAccess || TaskActionCellFactory.getActionCellsOls([modelBean.eventId])),
                fls: FlsService.getFls(), //,
                isWorkflowChained: TaskTiersService.isWorkflowChained(),
            }).then(function (resp) {
                modelBean.workflowChained = resp.isWorkflowChained;
                modelBean.currentContactId = resp.currentContactId;
                modelBean.editAccess =
                    modelBean.editAccess ||
                    S25Util.propertyGetParentWithChildValue(resp.ols, "object_id", modelBean.eventId)?.access_level;

                modelBean.hasCancelPerms =
                    modelBean.editAccess &&
                    modelBean.editAccess !== "N" &&
                    resp.fls.TASK_CANCEL === "F" &&
                    parseInt(modelBean.itemTypeId) === 3 &&
                    parseInt(modelBean.itemStateId) === 1 &&
                    parseInt(modelBean.requesterId) === resp.currentContactId;

                //if task is in-progress AND (admin OR assigned to user)
                if (
                    parseInt(modelBean.itemStateId) === 1 && //in-progress task
                    // resp.groupId===-1 //DBA-187
                    //     ||
                    ([101, 102].indexOf(parseInt(modelBean.todoType)) > -1 || //ANG-1389
                        parseInt(modelBean.assignedToId) === modelBean.currentContactId)
                ) {
                    modelBean.hasApproveDenyPerms = true;
                    modelBean.denyText = "Deny";
                    switch (parseInt(modelBean.itemTypeId)) {
                        case 1:
                            modelBean.approveText = "Acknowledge";
                            break;
                        case 3:
                            modelBean.approveText = "Assign";
                            break;
                        case 4:
                            modelBean.approveText = "UnAssign";
                            break;
                        case 5:
                            modelBean.approveText = "Complete";
                            modelBean.denyText = "Ignore";
                            break;
                        default:
                            modelBean.approveText = "Approve";
                            break;
                    }
                } else if (
                    parseInt(modelBean.itemStateId) === -1 &&
                    parseInt(modelBean.assignedToId) === modelBean.currentContactId
                ) {
                    //if invalid-state and assigned to user
                    modelBean.hasApproveDenyPerms = false;
                    modelBean.itemText = "See Event";
                } else if (
                    modelBean.itemTypeId === Task.Ids.Authorization &&
                    parseInt(modelBean.itemStateId) === Task.States.Declined
                ) {
                    // ANG-1418 -- notify tasks can be approved after denial
                    modelBean.hasApproveDenyPerms = true;
                    modelBean.approveText = "Approve Previously Denied";
                } else {
                    //else: no actions for user...
                    modelBean.hasApproveDenyPerms = false;
                    modelBean.itemText = "None";
                }

                modelBean.itemStateName = TaskService.taskStateToStateText(modelBean.itemStateId, modelBean.itemTypeId);
                modelBean.init = true;
            });
        };

        modelBean.initF();

        return modelBean;
    }
}

export class TaskListService {
    /* Task Overview List */
    //form list model for overview of tasks
    //also task list for task search...
    public static getTaskOverviewListFn(listModelBean: any) {
        let NO_DATA = "none";
        let colsArray = [
            { name: "Event", templateType: 5, prefname: "event", sortable: 1, isDefaultVisible: 1, isPermanent: 1 },
            { name: "Title", sortable: 1, prefname: "title", isDefaultVisible: 1 },
            { name: "Event State", sortable: 1, prefname: "event_state", isDefaultVisible: 1 },
            { name: "Task Item", sortable: 1, prefname: "task_item", isDefaultVisible: 1 },
            { name: "Type", prefname: "type", sortable: 1, isDefaultVisible: 1 },
            { name: "Status", sortable: 1, prefname: "status", isDefaultVisible: 1 },
            { name: "Actions", sortable: 0, prefname: "actions", isDefaultVisible: 1 },
            // {name: "Flagged", sortable: 0, prefname: "flagged", isDefaultVisible: 1},
            { name: "Respond By", sortable: 1, prefname: "respond_by", isDefaultVisible: 1 },
            { name: "First Date", sortable: 1, prefname: "first_date", isDefaultVisible: 1, isDate: 1 },
            { name: "Reference", sortable: 1, prefname: "reference", isDefaultVisible: 1 },
            { name: "Assigned By", sortable: 1, prefname: "assigned_by", isDefaultVisible: 1 },
            { name: "Assigned To", sortable: 1, prefname: "assigned_to", isDefaultVisible: 1 },
            { name: "Scheduler", sortable: 1, prefname: "scheduler", isDefaultVisible: 1 },
        ];

        // colsArray.push({name: "Add Assignee", sortable: 0, prefname: "add_assignee", isDefaultVisible: 1});

        let rowsF = function (listData: any) {
            let listModelBeanCp = {
                editCell: listModelBean.editCell,
                getCell: listModelBean.getCell,
                getRow: listModelBean.getRow,
                refreshListBindings: listModelBean.refreshListBindings,
            };
            let ret: any = [];
            if (listData && listData.tasks && listData.tasks.task) {
                jSith.forEach(listData.tasks.task, function (key: any, obj: any) {
                    //set data rows
                    let isTodo = parseInt(obj.task_type) === 5 || parseInt(obj.task_type) === 6;
                    ret.push({
                        contextId:
                            (isTodo ? "todo" : "task") + (parseInt(obj.task_count) > 1 ? "_multi_" : "") + obj.task_id,
                        row: [
                            (obj.event_name && {
                                templateType: 5,
                                itemId: obj.event_id,
                                itemTypeId: 1,
                                itemName: obj.event_name,
                            }) ||
                                NO_DATA, //this cell is a template type, see s25-list tmpls for the markup this will use vis-a-vis s25ListTemplateCell
                            obj.event_title,
                            obj.event_state,

                            //Task Item:
                            //note: assuming task_state is the state for this user if multiple contacts on the task (this drives what actions are available in the modal when clicked)...
                            {
                                isTodo: isTodo,
                                eventId: obj.event_id,
                                templateType: 7,
                                itemId: parseInt(obj.task_id),
                                itemName: obj.task_name,
                                itemTypeId: obj.task_type,
                                todoSubType: obj.task_subtype,
                                itemStateId: obj.task_state,
                                itemCount: parseInt(obj.task_count),
                                listModelBean: listModelBeanCp,
                                workflowChained: listData.workflowChained,
                                taskBlocked: obj.task_blocked && obj.task_blocked === "T",
                            },

                            obj.task_type_name,

                            obj.task_blocked && obj.task_blocked === "T" && listData.workflowChained
                                ? {
                                      templateType: 40,
                                      task: {
                                          itemId: parseInt(obj.task_id),
                                          itemStateId: obj.task_state,
                                          itemTypeId: obj.task_type,
                                          taskName: obj.task_name || obj.event_name,
                                          objectType: parseInt(obj.object_type),
                                          objectId: parseInt(obj.object_id),
                                          assignedToId: obj.assigned_to_id,
                                          assignedById: obj.assigned_by_id,
                                          taskBlocked: obj.task_blocked && obj.task_blocked === "T",
                                          blockTask: obj.blocking_task || [],
                                          workflowChained: listData.workflowChained,
                                      },
                                  }
                                : {
                                      itemName: TaskService.taskStateToStateText(obj.task_state, obj.task_type),
                                      itemStateId: obj.task_state,
                                  },
                            /*
                            {
                                itemName:
                                    obj.task_blocked && obj.task_blocked === "T" && listData.workflowChained
                                        ? "Pending"
                                        : TaskService.taskStateToStateText(obj.task_state, obj.task_type),
                                itemStateId: obj.task_state,
                            },
                            */
                            //Actions:
                            //note: assuming task_state is the state for this user if multiple contacts on the task (this drives what actions are available)...
                            obj.task_blocked && obj.task_blocked === "T" && listData.workflowChained
                                ? "None"
                                : TaskActionCellFactory.generateActionCell(
                                      {
                                          templateType: 8,
                                          itemId: parseInt(obj.task_id),
                                          itemStateId: obj.task_state,
                                          itemTypeId: obj.task_type,
                                          itemCount: parseInt(obj.task_count),
                                          eventId: obj.event_id,
                                          todoSubType: obj.task_subtype,
                                          taskName: obj.task_name || obj.event_name,
                                          objectType: parseInt(obj.object_type),
                                          objectId: parseInt(obj.object_id),
                                          assignedToId: obj.assigned_to_id,
                                          assignedById: obj.assigned_by_id,
                                          isTodo: isTodo,
                                          taskBlocked: obj.task_blocked && obj.task_blocked === "T",
                                          blockTask: obj.blocking_task || [],
                                          workflowChained: listData.workflowChained,
                                      },
                                      listModelBeanCp,
                                  ),

                            //Flagged:
                            // {templateType: 27, value: obj.read === "F", label: "Flagged", action: function() {
                            //
                            // }},

                            //Respond By
                            {
                                templateType: 21,
                                date: obj.respond_by,
                                taskId: parseInt(obj.task_id),
                                isTodo: isTodo,
                                taskCount: parseInt(obj.task_count),
                                eventId: obj.event_id,
                                objectType: parseInt(obj.object_type),
                                objectId: parseInt(obj.object_id),
                                assignedToId: obj.assigned_to_id,
                                assignedById: obj.assigned_by_id,
                            },

                            obj.first_date,
                            obj.reference,
                            obj.assigned_by,
                            TaskService.formSingleContactString(obj.assigned_to, obj.assigned_to_id, listData.currId),
                            TaskService.formSingleContactString(obj.scheduler_name, obj.scheduler_id, listData.currId),
                            // {
                            //     templateType: 39,
                            //     task: obj
                            // }
                        ],
                    });
                });
            }
            return ret;
        };
        return ListGeneratorService.s25Generate(
            "25L_task_overview_columns",
            colsArray,
            rowsF,
            TaskListService.getTasksForOverviewList,
        );
    }

    //task list needs some extra values like lang and current user id
    public static getTasksForOverviewList(params: any) {
        return S25Util.all({
            tasks: TaskService.getTasks(params),
            lang: LangService.getLang(),
            currId: UserprefService.getContactId(),
            prefs: PreferenceService.getPreferences(["workflowChained"], "S"),
        }).then(function (resp) {
            resp.tasks.lang = resp.lang;
            resp.tasks.currId = resp.currId;
            resp.prefs && resp.prefs.workflowChained.value === "T"
                ? (resp.tasks.workflowChained = true)
                : (resp.tasks.workflowChained = false);
            return resp.tasks;
        });
    }

    //default getter for s25-task-overview-list
    public static getTaskOverviewList(params: any, listModelBean: any) {
        return TaskSelectionMap.compute(params.modelBean.searchQuery).then(function (taskParams) {
            params.taskBean = taskParams;
            return TaskListService.getTaskOverviewListFn(listModelBean)(TaskService.scopeToDataRequest(params)); //run service / dao
        });
    }

    //returns array of tasks, combining todos and approvals into one streamlined model
    @Timeout
    public static getEventTasks(params: any) {
        return S25Util.all({
            events: EventService.getEventTasks(params.modelBean.optBean.showRelatedEvents, params.modelBean.itemId),
            currentContactId: UserprefService.getContactId(),
            lang: LangService.getLang(),
            isWorkflowChained: TaskTiersService.isWorkflowChained(),
        }).then(
            function (resp) {
                let tasks: TaskListI[] = [];
                if (resp && resp.events && resp.events.length) {
                    jSith.forEach(resp.events, function (key: any, event: any) {
                        let eventData = event;
                        //console.log({ eventData460: eventData });
                        if (eventData) {
                            let requester = S25Util.propertyGetParentWithChildValue(
                                eventData,
                                "role_id",
                                S25Const.requestorRole.event,
                            );
                            let requesterId = parseInt(S25Util.propertyGetVal(requester, "contact_id"));

                            if (eventData.todo) {
                                jSith.forEach(eventData.todo, function (key: any, obj: any) {
                                    if (
                                        !params.modelBean.hasAssignedTo ||
                                        (params.modelBean.hasAssignedTo &&
                                            resp.currentContactId === parseInt(obj.cur_assigned_to_id))
                                    ) {
                                        let typeId = 5; //to-dos are type 5: http://knowledge25.collegenet.com/display/WSW/Copy+of+tasks.xml
                                        tasks.push({
                                            reference: eventData.event_locator,
                                            taskItem: {
                                                eventId: eventData.event_id,
                                                templateType: 7,
                                                itemId: obj.todo_id,
                                                itemName: obj.todo_name,
                                                itemTypeId: typeId,
                                                itemStateId: obj.cur_todo_state,
                                                itemCount: 1,
                                                isTodo: true,
                                                todoType: obj.todo_type,
                                                requesterId: requesterId,
                                                todoSubType: obj.todo_subtype,
                                            },
                                            type: obj.todo_type_name,
                                            status: {
                                                itemName: TaskService.taskStateToStateText(obj.cur_todo_state, typeId),
                                                itemStateId: obj.cur_todo_state,
                                            },
                                            actions: {
                                                templateType: 8,
                                                itemId: obj.todo_id,
                                                itemStateId: obj.cur_todo_state,
                                                itemTypeId: typeId,
                                                itemCount: 1,
                                                eventId: eventData.event_id,
                                                taskName: obj.todo_name || eventData.event_name,
                                                objectType: 1,
                                                assignedToId: obj.cur_assigned_to_id,
                                                assignedById: obj.cur_assigned_by_id,
                                                requesterId: requesterId,
                                                todoType: obj.todo_type,
                                                todoSubType: obj.todo_subtype,
                                                isTodo: true,
                                            },
                                            flagged: {
                                                templateType: 28,
                                                isTodo: true,
                                                assignedToId: obj.cur_assigned_to_id,
                                                assignedById: obj.cur_assigned_by_id,
                                                requesterId: requesterId,
                                                active: true,
                                                value: obj.read === "F",
                                                label: "Flagged",
                                                action: function () {
                                                    return TaskService.setFlag(typeId, obj.todo_id, this.value);
                                                },
                                            },
                                            respondBy: {
                                                templateType: 21,
                                                date: obj.due_dt,
                                                taskId: obj.todo_id,
                                                isTodo: true,
                                                assignedToId: obj.cur_assigned_to_id,
                                                assignedById: obj.cur_assigned_by_id,
                                                requesterId: requesterId,
                                            },
                                            eventDate: eventData.start_date,
                                            assignedTo: {
                                                itemName: TaskService.formSingleContactString(
                                                    obj.cur_assigned_to,
                                                    obj.cur_assigned_to_id,
                                                    resp.currentContactId,
                                                ),
                                                itemId: obj.cur_assigned_to_id,
                                                templateType: 24,
                                            },
                                            comments: {
                                                templateType: 22,
                                                value: obj.todo_description || "",
                                                isTodo: true,
                                                taskId: obj.todo_id,
                                                assignedToId: obj.cur_assigned_to_id,
                                                assignedById: obj.cur_assigned_by_id,
                                                requesterId: requesterId,
                                            },
                                            hasApproveDenyPerms:
                                                resp.currentContactId === parseInt(obj.cur_assigned_to_id),
                                            taskBlocked: "F", // todo no taskblocked
                                            blockTask: [], // todo no taskblocked
                                            workflowChained: resp.isWorkflowChained,
                                        });
                                    }
                                });
                            }

                            if (eventData.approval) {
                                let filterContactId = function (obj: any) {
                                    return parseInt(obj.approval_contact_id) === resp.currentContactId;
                                };
                                let firstRsrvDateByRsrvId: any = {};
                                let profiles = S25Util.propertyGet(eventData, "profile");
                                profiles &&
                                    jSith.forEach(profiles, function (key: any, prof: any) {
                                        jSith.forEach(prof.reservation, function (key: any, rsrv) {
                                            firstRsrvDateByRsrvId[rsrv.reservation_id] = rsrv.reservation_start_dt;
                                        });
                                    });

                                jSith.forEach(eventData.approval, function (key: any, approval: any) {
                                    let approvalContacts = approval.approval_contact || [];
                                    let currentUserApprovalContact = approvalContacts.filter(filterContactId);
                                    currentUserApprovalContact =
                                        currentUserApprovalContact.length && currentUserApprovalContact[0];

                                    let itemId = approval.approval_id;
                                    let itemName = approval.approval_name;
                                    let itemQuantity = approval.request_quantity;
                                    let objectType = approval.object_type;
                                    let typeInfo = TaskService.extractApprovalTypeInfo(approval, resp.currentContactId);
                                    let itemTypeId = typeInfo.typeId;
                                    let itemTypeName = typeInfo.typeName;

                                    let userItemStateId =
                                        (currentUserApprovalContact &&
                                            currentUserApprovalContact.approval_contact_state) ||
                                        approval.approval_state;
                                    let uniqueUserStates = (
                                        S25Util.propertyGetAllUnique(approvalContacts, "approval_contact_state") || []
                                    ).map(S25Util.toInt);
                                    let flagged =
                                        currentUserApprovalContact && currentUserApprovalContact.read_state === "F";
                                    let itemStateId;

                                    //if denied exists in a user state, the whole thing is denied no matter what (WS has overall state as "complete" UHG)
                                    if (uniqueUserStates.indexOf(3) > -1) {
                                        itemStateId = 3;
                                    } else {
                                        itemStateId = approval.approval_state;
                                    }

                                    let itemStateName = TaskService.taskStateToStateText(itemStateId, itemTypeId);
                                    let hasApproveDenyPerms = !!currentUserApprovalContact;

                                    if (
                                        !params.modelBean.hasAssignedTo ||
                                        (params.modelBean.hasAssignedTo && hasApproveDenyPerms)
                                    ) {
                                        tasks.push({
                                            reference: eventData.event_locator,
                                            taskItem: {
                                                eventId: eventData.event_id,
                                                templateType: 7,
                                                itemId: itemId,
                                                itemName: itemName,
                                                itemTypeId: itemTypeId,
                                                itemStateId: userItemStateId,
                                                itemCount: 1,
                                                itemQuantity: itemQuantity,
                                                objectType: objectType,
                                                requesterId: requesterId,
                                            },
                                            type: itemTypeName,
                                            status: { itemName: itemStateName, itemStateId: itemStateId }, //overall task state
                                            actions: {
                                                templateType: 8,
                                                itemId: itemId,
                                                itemStateId: userItemStateId,
                                                itemTypeId: itemTypeId,
                                                itemCount: 1,
                                                eventId: eventData.event_id,
                                                taskName: itemName || eventData.event_name,
                                                objectType: approval.object_type,
                                                assignedToId: hasApproveDenyPerms ? resp.currentContactId : -999,
                                                assignedById: approval.assigned_by_id,
                                                requesterId: requesterId,
                                            },
                                            flagged: {
                                                templateType: 28,
                                                assignedToId: hasApproveDenyPerms ? resp.currentContactId : -999,
                                                assignedById: approval.assigned_by_id,
                                                requesterId: requesterId,
                                                active: !!currentUserApprovalContact,
                                                value: flagged,
                                                label: "Flagged",
                                                action: function () {
                                                    return TaskService.setFlag(itemTypeId, itemId, this.value);
                                                },
                                            },
                                            respondBy: {
                                                templateType: 21,
                                                date: approval.respond_by,
                                                taskId: itemId,
                                                taskCount: 1,
                                                eventId: eventData.event_id,
                                                assignedToId: hasApproveDenyPerms ? resp.currentContactId : -999,
                                                assignedById: approval.assigned_by_id,
                                                requesterId: requesterId,
                                            },
                                            eventDate:
                                                firstRsrvDateByRsrvId[approval.approval_profile_id] ||
                                                eventData.start_date,
                                            assignedTo: {
                                                itemName: TaskService.formTaskContactString(
                                                    hasApproveDenyPerms,
                                                    resp.currentContactId,
                                                    approval,
                                                ),
                                                task: approval,
                                                eventId: eventData.event_id,
                                                templateType: 24,
                                            },
                                            comments: {
                                                templateType: 22,
                                                value: approval.approval_comments || "",
                                                taskId: itemId,
                                                eventId: eventData.event_id,
                                                assignedToId: hasApproveDenyPerms ? resp.currentContactId : -999,
                                                assignedById: approval.assigned_by_id,
                                                requesterId: requesterId,
                                            },
                                            objectType: approval.object_type,
                                            hasApproveDenyPerms: hasApproveDenyPerms,
                                            taskBlocked: approval.task_blocked || "F",
                                            blockTask: approval.blocking_task || [],
                                            workflowChained: resp.isWorkflowChained,
                                        });
                                    }
                                });
                            }
                        }
                    });
                }
                return tasks;
            },
            (err) => {
                console.log("err", err);
                return [];
            },
        );
    }
}
